import { Switch, Route, Redirect } from "react-router-dom";
import ROUTES from "@statics/routes";
import Home from "@pages/Home";
import ProjectOverview from "@pages/ProjectV2/Pages/ProjectOverview/ProjectOverview";
import ProjectV2Wrapper from "@pages/ProjectV2/Wrapper/ProjectV2Wrapper";
import GetInvolved from "@pages/GetInvolved/GetInvolved";
import People from "@pages/People/People";
import Affiliations from "@pages/Affiliations/Affiliations";
import { DefaultTimeline } from "@pages/Timeline";
import ErrorPage from "@pages/404ErrorPage/ErrorPage";
import Resources from "@pages/Resources";
import ResourcePage from "@pages/Resources/Page/ResourcePage";
import EditProfile from "@pages/EditProfile";
import AddTimelineEntry from "@pages/Timeline/AddTimelineEntry/AddTimelineEntry";
import EditTimelineEntry from "@pages/Timeline/EditTimelineEntry/EditTimelineEntry";
import { useAppSelector } from "@redux/hooks";
import { selectIsLoggedIn } from "@redux/slices/AuthRedux";
import { useEffect, useState } from "react";

require("dotenv").config();

const AppSwitch = () => {
	// redux variable that keeps track if the user is logged in or not
	const isLoggedIn = useAppSelector(selectIsLoggedIn);

	return (
		<Switch>
			<Route exact path={ROUTES.HOME} component={Home} />
			<Route exact path={ROUTES.PROJECT.BASE} component={ProjectOverview} />
			<Route exact path={ROUTES.GET_INVOLVED} component={GetInvolved} />
			<Route exact path={ROUTES.PEOPLE} component={People} />
			<Route exact path={ROUTES.AFFILIATIONS} component={Affiliations} />
			<Route path={ROUTES.PROJECT.PATH} component={ProjectV2Wrapper} />

			<Route exact path={ROUTES.TIMELINE}>
				{isLoggedIn ? <DefaultTimeline /> : <Redirect to='/' />}
			</Route>

			<Route exact path={ROUTES.TIMELINE_ADD}>
				{isLoggedIn ? <AddTimelineEntry /> : <Redirect to='/' />}
			</Route>

			<Route exact path={ROUTES.TIMELINE_EDIT}>
				{isLoggedIn ? <EditTimelineEntry /> : <Redirect to='/' />}
			</Route>

			<Route exact path={ROUTES.RESOURCES?.BASE}>
				{isLoggedIn ? <Resources /> : <Redirect to='/' />}
			</Route>

			<Route exact path={ROUTES.PROFILE}>
				{isLoggedIn ? <EditProfile /> :
					<Redirect to='/' />
				}
			</Route>

			{/* The ResourcePage component has special props. I can't render it like the other routes */}
			{isLoggedIn ? (
				<Route exact path={ROUTES.RESOURCES?.PATH} component={ResourcePage} />
			) : <Redirect to='/' />}

			{/* Catch-all */}
			<Route path={ROUTES.ERROR_PAGE} component={ErrorPage} />
		</Switch>
	);
};

export default AppSwitch;
