import axios from 'axios';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from './EditTimelineEntry.module.css';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@redux/hooks';
import { appActions } from '@redux/slices/AppRedux';
import TitleInput from '@components/EditTimeline/TitleInput/TitleInput';
import DateInput from '@components/EditTimeline/DateInput/DateInput';
import ProjectInput from '@components/EditTimeline/ProjectInput/ProjectInput';
import CategoryInput from '@components/EditTimeline/CategoryInput/CategoryInput';
import ContributorInput from '@components/EditTimeline/ContributorInput/ContributorInput';
import HyperlinkInput from '@components/EditTimeline/HyperlinkInput/HyperlinkInput';
import DescriptionInput from '@components/EditTimeline/DescriptionInput/DescriptionInput';
import Controls from '@components/EditTimeline/Controls/Controls';
import { MdErrorOutline } from 'react-icons/md';
import { TimelineInfo } from '../types';

type TimelineParams = {
  timeline_id: string;
};

interface TimelineProps { }



const EditTimelineEntry: React.FC<TimelineProps> = (props) => {
  const dispatch = useAppDispatch();
  const [showError, setError] = useState<boolean>(false);

  const [timeline, setTimeline] = useState<TimelineInfo>({ title: "", descriptions: "", date: new Date(), project: "", author: "", categories: [], contributors: [], hyperlinks: [] });

  const [currentCategories, setCategories] = useState<string[]>([])

  const history = useHistory();

  // gets the currently logged in User's username
  const currUser = useSelector((state: any) => state.auth.username);

  let { timeline_id } = useParams<TimelineParams>();

  useEffect(() => {
    axios.get(`/api/snapshots/${timeline_id}`).then((res) => {
      const { title, descriptions, date, project, author, categories, contributors, hyperlinks } = res.data.data;
      setCategories(categories)
      setTimeline({
        title,
        descriptions,
        project,
        date:new Date(date),
        author,
        categories,
        contributors,
        hyperlinks
      })
    }).catch((err) => {
      window.location.href = '/errorPage';
      // console.log(err)
    })
  }, []);

  const toggleCategory = (category: string): void => {
    if (currentCategories.includes(category)) {
      setCategories(currentCategories.filter((c) => c != category))
      document.getElementById(category)!.style.backgroundColor = '#DEE3EA';
      document.getElementById(category)!.style.color = '#1C426D';
    } else {
      setCategories([...currentCategories, category])
      document.getElementById(category)!.style.backgroundColor = '#1C426D';
      document.getElementById(category)!.style.color = '#DEE3EA';
    }
  };

  function isEmpty(value: string) {
    return (value == null || (typeof value === "string" && value.trim().length === 0));
  }


  const save = async () => {

    setError(false)

    if (isEmpty(timeline.title) || isEmpty(timeline.project) || (currentCategories.length <= 0) || isEmpty(timeline.descriptions)) {
      setError(true)
      return
    }

    await axios({
      method: 'put',
      url: `/api/snapshots/${timeline_id}`,
      data: {
        title: timeline.title,
        descriptions: timeline.descriptions,
        date: timeline.date.toISOString(),
        project: timeline.project,
        author: currUser,
        categories: currentCategories,
        contributors: timeline.contributors,
        hyperlinks: timeline.hyperlinks,
      },
    }).then(() => {
      history.push("/timeline");
    }).catch(err => {
      setError(true);
      dispatch(appActions.setAlert(err.message));
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.headers}>
          <h1 className={styles.header}>Edit Timeline Entry</h1>
          <hr></hr>
          <h2 className={styles.subHeader} style={{ color: 'red', fontSize: '15px' }}>NOTE: * = mandatory field</h2>
        </div>
        <main>
          <div className={styles.gridContainer}>
            <div className={styles.basicInfo}>
              <div><TitleInput timeline={timeline} setTimeline={setTimeline} /></div>
              <div><DateInput timeline={timeline} setTimeline={setTimeline}/></div>
            </div>

            <div className={styles.projectInfo}>
              <ProjectInput timeline={timeline} setTimeline={setTimeline} />
            </div>

            <div className={styles.categoryInfo}>
              <CategoryInput timeline={timeline} setTimeline={setTimeline} selectedCategories={currentCategories} toggleCategory={toggleCategory} />
            </div>

            <div className={styles.contributorInfo}>
              <div><ContributorInput timeline={timeline} setTimeline={setTimeline} /></div>
              <div><HyperlinkInput timeline={timeline} setTimeline={setTimeline} /></div>
            </div>

            <div className={styles.descriptionInfo}>
              <DescriptionInput timeline={timeline} setTimeline={setTimeline} />
            </div>

            <div className={styles.controls}>
              <Controls save={save} />
            </div>

          </div>

        </main>
        <div className={styles.errorMessageContainer} style={{ visibility: `${showError ? 'visible' : 'hidden'}`, cursor: 'pointer' }}>
          <MdErrorOutline className={styles.errorMessageIcon} />
          <div>
            <b>WARNING: An error occured. Please try again.</b>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditTimelineEntry