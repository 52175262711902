import React, { useState, useEffect } from 'react';
import './PageNav.css';

interface PageNavProps {
    items: string[];
    currentSelection: string;
    onItemSelected: (item: string) => void;
    width?: string;
    defaultColumns?: number;
}

const PageNav: React.FC<PageNavProps> = ({ items, currentSelection, onItemSelected, width = '70%', defaultColumns = 7 }) => {
    const [columns, setColumns] = useState(getGridColumns(items.length, window.innerWidth, defaultColumns));

    useEffect(() => {
        const handleResize = () => {
            setColumns(getGridColumns(items.length, window.innerWidth, defaultColumns));
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [defaultColumns]);

    const gridStyle = {
        width: width,
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
    };

    return (
        <div className="page-nav" style={gridStyle}>
            {items.map((item, index) => {
                let displayedTitle:string = item;
                if (item == 'Image Transitions')
                    displayedTitle = 'IT'
                return(
                <div key={index} className="nav-item" onClick={() => onItemSelected(item)}>
                    <div className={`hover-item ${currentSelection === item ? "selected-item" : ""}`} id={item.toLowerCase()}>
                        {displayedTitle}
                    </div>
                </div>
            )})}
        </div>
    );
};

const getGridColumns = (itemCount: number, width: number, defaultColumns: number) => {
    if (width <= 700) {
        if (itemCount > 5) {
            return 2
        } else {
            return 1
        }
    } else {
        return defaultColumns;
    }
};

export default PageNav;