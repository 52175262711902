import { ROUTES, TEXT } from "@statics";
import VCLIcon from "@statics/images/vcl-logo-2023.png";

const IS_WIP = true

export default function Logo() {

    return (
          <div className="logo-container">
            <a href={ROUTES.HOME}>
              <img src={VCLIcon} alt="VCL logo" className="vcl-logo" />
            </a>
            <a href={ROUTES.HOME} className="vcl-title-link">
              {(!IS_WIP || (window.innerWidth >= 920)) && TEXT.COMMON.LAB_TITLE_ONLY}
            </a>
          </div>
    )
}