

// prevents menu items with hover transformations from running before dropdown fully opens
function setNoHover() {
    const els = document.getElementsByClassName("item-hoverable");
    for (let i = 0; i < els.length; i++) {
        els[i].classList.add("no-hover");
    }
}

// stops the event from propagating to parent
function stopTransitionEventPropagation(e: React.TransitionEvent) {
    e.stopPropagation();
}


// dynamically determines dropdown menu width on open and maintains that initial width when hovering menu items
//    child elements with transition events will pass those up. to prevent this, child elements with transitions 
//    call stopTransitionEventPropagation()
const setMenuSizeAfterTransition = () => {
    const menuContainerPaper: HTMLDivElement | null | undefined = document.getElementById("basic-menu")?.querySelector(".MuiList-root");
    if (menuContainerPaper && !menuContainerPaper.style.width || menuContainerPaper?.style.width === "calc(100% + 0px)") {
        menuContainerPaper.style.width = menuContainerPaper.getBoundingClientRect().width.toString() + "px";
        const els = document.getElementsByClassName("item-hoverable");
        for (let i = 0; i < els.length; i++) {
        els[i].classList.remove("no-hover");
        }
    }
}

export { setNoHover, stopTransitionEventPropagation, setMenuSizeAfterTransition }
