import GenericLink from "@components/generics/Link";
import { NAV, TEXT } from "@statics";
import LinkWithMenu from "./LinkWithMenu";
import { IconButton } from "@mui/material";
import { ReactComponent as SearchIcon } from "@statics/images/search-icon.svg";
import { useLocation } from "react-router-dom";
import ProfileDropdown from "@components/ProfileDropdown";
import { useAppSelector } from "@redux/hooks";
import { selectIsLoggedIn } from "@redux/slices/AuthRedux";

interface props {
	IS_WIP: boolean;
}

export default function NavbarLinks({ IS_WIP }: props) {
	// Hook from react-router-dom to track active page
	const location = useLocation();
	const isLoggedIn = useAppSelector(selectIsLoggedIn);

	const handleSearchBtnClick = () => {
		// todo
	};

	return (
		<span className="nav-rendered-links">
			{NAV.map(({ TITLE, REF }, index) => {
				let active = REF === location.pathname ? "active" : "";
				if (isLoggedIn) {
					switch (TITLE) {
						case "Resources":
							active = location.pathname.includes(REF) ? "active" : "";
							return <LinkWithMenu key={index} active={active} refs={REF} />;
						case "Projects":
							active = location.pathname.includes(REF) ? "active" : "";
							return <LinkWithMenu key={index} active={active} refs={REF} />;
						default:
							return (
								<GenericLink
									key={REF}
									name={TITLE}
									to={REF!}
									className={`nav-link ${active}`}
								/>
							);
					}
				} else {
					switch (TITLE) {
						case "Resources":
							return <></>;
						case "Timeline":
							return <></>;
						case "Projects":
							active = location.pathname.includes(REF) ? "active" : "";
							return <LinkWithMenu key={index} active={active} refs={REF} />;
						default:
							return (
								<GenericLink
									key={REF}
									name={TITLE}
									to={REF!}
									className={`nav-link ${active}`}
								/>
							);
					}
				}
			})}

			{/* Search icon */}
			{IS_WIP && (
				<IconButton onClick={handleSearchBtnClick}>
					<SearchIcon />
				</IconButton>
			)}
			<ProfileDropdown />
		</span>
	);
}
