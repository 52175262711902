import { TimelineInfo } from '@pages/Timeline/types';
import styles from './CategoryInput.module.css';

interface PropsOBJ {
  setTimeline:(input:TimelineInfo) => void;
  selectedCategories: string[];
  toggleCategory:(input:string) => void;
  timeline:TimelineInfo;
}

const CategoryInput = ({ selectedCategories, toggleCategory }: PropsOBJ) => {
  const categories = ['Workshops','Conditions','Meetings','Guest Speaker','Hiring', 'Resources', 'Progress', 'Dev/Code', 'Analysis', 'Other'];

  return (
    <div className={styles.container}>
      <div className={styles.title}><label>Category</label><label style={{color: "red"}}>*</label></div>
      <div className={styles.buttonContainer}>
        {categories.map((category, index) => (
          <button 
            id={category} 
            onClick={() => toggleCategory(category)} 
            key={index}
            style={{
              backgroundColor: selectedCategories.includes(category) ? '#1C426D' : '#DEE3EA',
              color: selectedCategories.includes(category) ? '#DEE3EA' : '#1C426D'
            }}
          >
            {category}
          </button>
        ))}
      </div>
    </div>
  );
}

export default CategoryInput;
