import "./ProjectRouter.css";
import { Route, Redirect, Switch } from "react-router-dom";
import ProjectV2Default from "@pages/ProjectV2/Pages/Default/ProjectV2Default";
import ProjectV2Publications from "../Pages/Publications/ProjectV2Publications";
import ProjectV2Team from "../Pages/Team/ProjectV2Team";
import PROJECT_TEXT, { PROJECT } from "@statics/projectsV2";
import ProjectV2Join from "../Pages/Join/ProjectV2Join";

interface PropsOBJ {
  project_id: string;
  isPages: boolean;
  subpages: string[];
}

const ProjectRouter = (props: PropsOBJ) => {
  const { project_id, isPages, subpages } = props;

  let currentProject: PROJECT | undefined = PROJECT_TEXT.find(
    (element) => element.project_title.toLowerCase() == project_id.toLowerCase()
  );

  if (!currentProject) {
    window.location.href = "/errorPage"
  }

  return (
    <Switch>
      {PROJECT_TEXT.map((p, index) => {
        return (
          <Route
            exact
            path={`/projects/${p.project_title}/`}
            render={() => {
              return <ProjectV2Default project_id={p.project_title} subPage="" />;
            }}
            key={index}
          />
        )
      })}
      <Route
        exact
        path={`/projects/${project_id}/join`}
        render={() => {
          if (!(currentProject!.joinTeam!.whatWeDo!.length > 0))
            window.location.href = '/errorPage'

          return <ProjectV2Join project_id={project_id} />;
        }}
      />
      <Route
        exact
        path={`/projects/${project_id}/team`}
        render={() => {
          return <ProjectV2Team project={project_id} />;
        }}
      />
      <Route
        exact
        path={`/projects/${project_id}/publications`}
        render={() => {
          return <ProjectV2Publications project_id={project_id} />;
        }}
      />
      {subpages.map((subpage: string, index) => {
        return (
          <Route
            exact
            path={`/projects/${project_id}/${subpage}`}
            render={() => {
              return <ProjectV2Default project_id={project_id} subPage={subpage} />
            }} 
            key={index}/>
        );
      })}
      <Route path={`/projects/*`}>
        <Redirect to="/errorPage"/>
      </Route>
    </Switch>
  );
};

export default ProjectRouter;
