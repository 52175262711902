import { Props } from 'react-burger-menu';
import styles from './Controls.module.css';

interface PropsOBJ {
    // fundamentally (without specific implementation, edit and add use the same return statements)
    save:() => any;
}

const Controls = ({save}:PropsOBJ) => {
    const clearForm = () => {
        window.location.href = "/timeline";
    }
  
    return (
        <div>
            <button className={styles.cancelButton} onClick={() => clearForm()}>Cancel</button>
            <button className={styles.addButton} onClick={() => save()}>Submit</button>
        </div>
    )
  }
  
  export default Controls