import Group5 from '@statics/images/Group 5.png'
import './ErrorPage.css';

const ErrorPage = () => {

    return (
        <div className='errorPage-main-container'>
            <img className='errorPage-main-icon' src={Group5} />
            <div className='errorPage-text-container'>
                <h1>404 Error</h1>
                <h2>Hmm... We can’t seem to find this page.</h2>
                <div className='errorPage-redirect-button' style={{cursor:'pointer'}}onClick={() => window.location.href = "/"}>Take me home!</div>
            </div>
        </div>
    )
}

export default ErrorPage;