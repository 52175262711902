import styles from './DateInput.module.css'
import { useEffect, useState } from 'react'
import { TimelineInfo } from '@pages/Timeline/types';


interface PropsOBJ {
  setTimeline: (input: TimelineInfo) => void
  timeline: TimelineInfo;
}

const DateInput = ({ timeline, setTimeline }: PropsOBJ) => {

  let months = [
    "January", "February", "March", "April", "May", "June", "July", "August",
    "September", "October", "November", "December"
  ]

  let days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9,
    10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
    20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
    30, 31
  ]

  let years = [
    timeline.date.getFullYear() - 4, timeline.date.getFullYear() - 3, timeline.date.getFullYear() - 2, timeline.date.getFullYear() - 1, timeline.date.getFullYear(), timeline.date.getFullYear() + 1, timeline.date.getFullYear() + 2, timeline.date.getFullYear() + 3, timeline.date.getFullYear() + 4
  ]

  return (
    <div className={styles.inputForm}>
      <label>Date</label><br></br>

      <div className={styles.container} placeholder='Month'>
        <select
          className={styles.selectMonth}
          value={months[timeline.date.getMonth()]} // Bind value to the state
          onChange={(res) => {
            const newIndex = months.findIndex((item) => item === res.target.value);
            setTimeline({...timeline, date:new Date(timeline.date.getFullYear(), newIndex, timeline.date.getDate())})
          }}
        >
          <option value="" disabled>Select an option</option>
          {months.map((month, index) => (
            <option value={month} key={index}>{month}</option>
          ))}
        </select>

        <select className={styles.selectDay}
          value={timeline.date.getDate()}
          onChange={(res) => {
            setTimeline({...timeline, date:new Date(timeline.date.getFullYear(), timeline.date.getMonth(), Number(res.target.value))})

          }}
        >
          <option value="" disabled>Select an option</option>
          {
            days.map((day, index) => (
              <option value={day} key={index}>{day}</option>
            ))
          }
        </select>

        <select className={styles.selectYear} value={timeline.date.getFullYear()}
          onChange={(res) => {
            setTimeline({...timeline, date:new Date(Number(res.target.value), timeline.date.getMonth(), timeline.date.getDate())})
          }}>
          <option value="" disabled>Select an option</option>
          {
            years.map((year, index) => (
              <option value={year} key={index}>{year}</option>
            ))
          }
        </select>
      </div>
    </div>
  )
}

export default DateInput