import { TimelineInfo } from '@pages/Timeline/types';
import styles from './ProjectInput.module.css';
import { useState, Component, useEffect } from "react";

interface PropsOBJ {
  setTimeline:(input:TimelineInfo) => void
  timeline: TimelineInfo;
}
const ProjectInput = ({timeline, setTimeline}:PropsOBJ) => {
  const projects = ['Coding Team','Correlation','IDEO','Image Transitions','NCIS', 'SHIVA', 'NOVA']
  const [active, setActive] = useState(timeline.project);

  const toggleProject = (project: any) => {
    setActive(project);
  }

  useEffect(() => {
    toggleProject(timeline.project)
  }, [timeline])

  return (
    <div className={styles.container}>
      <div className={styles.title}><label>Project</label><label style={{color: "red"}}>*</label></div>
      <div className={styles.buttonContainer}>
        {projects.map((item, index)=> (
            <button id={item} key={index} onClick={() => {toggleProject(item); 
              setTimeline({...timeline, project:item})
            }} style={active == item ? {backgroundColor: "#1C426D", color: "#DEE3EA"} : {backgroundColor: "#DEE3EA", color: "#1C426D"}}>{item}</button>
        ))}
      </div>
    </div>
  )
};

export default ProjectInput