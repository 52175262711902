import DeleteIcon from '@mui/icons-material/Delete';
import { Resource } from '@entities/Resource';
import { useAppSelector } from '@redux/hooks';
import { selectAuth } from '@redux/slices/AuthRedux';
import styles from "./ResourceEntry.module.css"
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { FcStackOfPhotos } from "react-icons/fc";

interface ResourceProps {
  resource: Resource,
  deleteResource: (resourceId: string) => void,
  editResource: (resource: Resource) => void,
}

export const ResourceEntry: React.FC<ResourceProps> = (props) => {
  const {resource, deleteResource, editResource} = props;
  const { username, permissions } = useAppSelector(selectAuth);

  // TODO: change after implementing file storage
  const imageSrc = '';

  return (
    <div className={styles.resourceEntryContainer}>
      <div className={styles.imageContainer}>
          {imageSrc ? (
            <img src={imageSrc}/>            
          ) : (
            <FcStackOfPhotos style={{width: '100%', height: '100%'}}/>
          )}
      </div>
      <div className={styles.contentContainer}>
          <div className={styles.titleContainer}>
              <a href={resource.resource_link.includes('://') ? resource.resource_link : "//" + resource.resource_link} target='_blank' rel="noopener noreferrer">
                  {resource.title}
              </a>
              <div className={styles.iconsContainer}>
                {(permissions === "admin" || resource.owner.username === username) && 
                    <div onClick={() => editResource(resource)}>
                        <BorderColorIcon/>
                    </div>
                }
                {(permissions === "admin" || resource.owner.username === username) && 
                    <div onClick={() => deleteResource(resource._id)}>
                        <DeleteIcon/>
                    </div>
                }
              </div>
          </div>
          <div className={styles.authorContainer}>
              {resource.author}
          </div>
          <p>
              {resource.description}
          </p>
      </div>
    </div>
  )
}
