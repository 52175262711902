import { TimelineInfo } from '@pages/Timeline/types';

import styles from './DescriptionInput.module.css';

interface PropsOBJ {
  setTimeline:(input:TimelineInfo) => void
  timeline: TimelineInfo;
}
const DescriptionInput = ({timeline, setTimeline}:PropsOBJ) => {
  return (
    <form className={styles.inputForm} >
      <label>Description</label><label style={{color: 'red'}}>*</label><br></br>
      <textarea placeholder='Write your description here...' 
      onChange={(res) => {
        setTimeline({...timeline, descriptions:res.target.value})
      }}
      value={timeline.descriptions}></textarea>
    </form>
  )
}

export default DescriptionInput