import React, { useState } from 'react';
import { selectIsLoggingIn } from '@redux/slices/AuthRedux';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import Modal, { ModalInjectedProps } from '@components/generics/Modal';
import { CONSTANTS, TEXT } from '@statics';
import { useHandleLogin } from '@services/authService';
import VCLIcon from "@statics/images/vcl-logo-2023.png";
import CloseIcon from '@mui/icons-material/Close';
import LoginForm from './LoginForm';
import './LoginView.css';
import { IconButton } from '@mui/material';
import { appActions } from '@redux/slices/AppRedux';

type LoginViewProps = Partial<ModalInjectedProps>;

const LoginView: React.FC<LoginViewProps> = (props) => {
  const { handleClose } = props;
  const isLoggingIn = useAppSelector(selectIsLoggingIn);

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 500);

  const { handleLogin, error, success } = useHandleLogin();

  const handleUserLogin = (user: string, pwd: string) => handleLogin(user, pwd);

  const dispatch = useAppDispatch();

  // Implememt this function when Sign Up Modal is done
  const handleOpenSignUpModal = () => {
    dispatch(
      appActions.openModal({
        key: CONSTANTS.MODALS.SIGNUP,
      })
    )

    handleClose && handleClose();
  };

  // This closes the model as soon as the authentication is done
  React.useEffect(() => {
    if (success) {
      handleClose && handleClose();
    }
  }, [handleClose, success]);

  // This is there just to check if the user resizes the screen width on a device
  // it will mount the changes if the screen width changes
  React.useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 500);
    };

    window.addEventListener("resize", handleResize);

    return () => {
        window.removeEventListener("resize", handleResize);
    };
}, []);

  return (
    <>
    <div className="login-view-root">
      <div className='login-view-close-button-container'>
      {!isMobile && <h3 className="login-view-title">{TEXT.LOGIN.TITLE}</h3>}
        <IconButton onClick={handleClose} disabled={isLoggingIn} aria-label="close">
          <CloseIcon fontSize='large'/>
        </IconButton>
      </div>
      <div className='login-view-container'>
        <div className='login-view-form'>
          {isMobile && <h3 className="login-view-title">{TEXT.LOGIN.TITLE}</h3>}
          <LoginForm onSubmit={handleUserLogin} disabled={isLoggingIn} />
        </div>
        <div className='login-view-img-container'>
          <img src={VCLIcon} alt="UBC Visual Cognition Lab Icon" className='login-view-img'/>
          <div className='login-view-img-title'>UBC Visual Cognition Lab</div>
        </div>
        {error && <p className="login-error">{`Error: ${error}`}</p>}
      </div>
    </div>
    <div className='login-view-new-account'>
    {TEXT.LOGIN.CREATE_ACCOUNT}<span className="login-view-signup-link" onClick={handleOpenSignUpModal}>{TEXT.LOGIN.CREATE_ACCOUNT_LINK}</span>
    </div>
    </>
  );
};

export const LoginModal = () => {
  return (
    <Modal name="login" modalKey={CONSTANTS.MODALS.LOGIN}>
      <LoginView />
    </Modal>
  );
};

export default LoginView;


