import { IconButton, Menu, MenuItem } from "@mui/material";
import { useAppSelector, useAppDispatch } from "@redux/hooks";
import { appActions } from "@redux/slices/AppRedux";
import { selectIsLoggedIn } from "@redux/slices/AuthRedux";
import { useHandleLogout } from "@services/authService";
import { CONSTANTS, TEXT, ROUTES } from "@statics";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import React from "react";
import styles from "./ProfileDropdown.module.css";
import GenericLink from "@components/generics/Link";

const IS_WIP = process.env.REACT_APP_WIP === "development";

export default function ProfileDropdown() {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const isLoggedIn = useAppSelector(selectIsLoggedIn);
	const { logout } = useHandleLogout();
	const dispatch = useAppDispatch();

	// HANDLERS
	// handles closing login modal
	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleOpenLoginModal = () => {
		dispatch(
			appActions.openModal({
				key: CONSTANTS.MODALS.LOGIN,
			})
		);
		handleMenuClose(); // this makes no difference?
	};

	const handleLogout = () => {
		logout();
		handleMenuClose();
	};

	const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	React.useEffect(() => {
		function handleResize() {
			handleMenuClose();
		}
		// Add event listener
		window.addEventListener("resize", handleResize);

		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<>
			<IconButton onClick={handleMenuClick}>
				{" "}
				<AccountCircleIcon />{" "}
			</IconButton>
			<Menu
				className={styles.loginMenu}
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleMenuClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				MenuListProps={{
					"aria-labelledby": "menu-button",
				}}
			>
				{isLoggedIn ? (
					<>
						<MenuItem
							className={`${styles.profileDropdownItem}`}
							disableRipple
						>
							<AccountCircleIcon/>
							<GenericLink
								className={`${styles.dropdownLink}`}
								to={ROUTES.PROFILE!}
								name={TEXT.COMMON.PROFILE}
							/>
						</MenuItem>


						<MenuItem
							className={`${styles.profileDropdownItem}`}
							disableRipple
						>
							<LogoutIcon sx={{ marginRight: "2px" }} />
							<p onClick={handleLogout}>{TEXT.COMMON.LOGOUT} </p>
						</MenuItem>
					</>
				) : (
					<MenuItem className={styles.profileDropdownItem} disableRipple>
						<LoginIcon />
						<p onClick={handleOpenLoginModal}>{TEXT.COMMON.LOGIN}</p>
					</MenuItem>
				)}
			</Menu>
		</>
	);
}
