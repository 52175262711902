import React, { useState } from 'react';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import * as yup from 'yup';
import Button from '@components/generics/Button';
import { CONSTANTS, TEXT } from '@statics';
import './SignUpForm.css';
import '../SignUpView.css';
import { Alert, Divider, FormControl, FormHelperText, MenuItem, Select } from '@mui/material';
import VCLIcon from "@statics/images/vcl-logo-2023.png";
import axios from 'axios';


// MOdify this once the back-end implementation is done

interface SignUpFinalFormProps {
    disabled?: boolean;
    handleOpenLoginModal: () => void;
}


interface SignUpForm {
    firstname: string;
    lastname: string;
    project: string;
    position: string;
    username: string;
    password: string;
    token: string;
}

const SignUpFinalForm: React.FC<SignUpFinalFormProps> = (props) => {

    const { handleOpenLoginModal } = props;

    const [schemaError, setSchemaError] = useState<boolean>(false);
    const [usernameError, setUsernameError] = useState<boolean>(false);
    const [tokenError, setTokenError] = useState<boolean>(false);
    const [otherError, setError] = useState<boolean>(false);

    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 500);

    React.useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    const schema = React.useMemo(
        () =>
            yup.object({
                firstname: yup
                    .string()
                    .required(TEXT.SIGNUP.FIRSTNAME_EMPTY_ERROR),
                lastname: yup
                    .string()
                    .required(TEXT.SIGNUP.LASTNAME_EMPTY_ERROR),
                project: yup
                    .string()
                    .required(TEXT.SIGNUP.PROJECT_EMPTY_ERROR),
                position: yup
                    .string()
                    .required(TEXT.SIGNUP.POSITION_EMPTY_ERROR),
                username: yup
                    .string()
                    .required(TEXT.SIGNUP.USERNAME_EMPTY_ERROR),
                password: yup
                    .string()
                    .required(TEXT.SIGNUP.PASSWORD_EMPTY_ERROR),
                token: yup
                    .string()
                    .required(TEXT.SIGNUP.TOKEN_EMPTY_ERROR),
            }),
        []
    );

    const form = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            project: '',
            position: '',
            username: '',
            password: '',
            token: '',
        }
        ,
        validationSchema: schema,
        onSubmit: (values) => {

        },
    });
    function capitalizeWordsWithCoPilot(str: string) {
        if (str === 'co-pilot') {
            return ('Co-pilot')
        } else {
            return str.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
        }
    }


    const handleSignup = async () => {

        setSchemaError(false)
        setUsernameError(false);
        setTokenError(false);
        setError(false);
        await axios({
            method: 'post',
            url: '/api/users/create',
            data: {
                firstname: form.values.firstname,
                lastname: form.values.lastname,
                project: form.values.project,
                position: capitalizeWordsWithCoPilot(form.values.position),
                username: form.values.username,
                password: form.values.password,
                token: form.values.token,
                tokenKey: form.values.position,
            }
        }).then((__) => {
            handleOpenLoginModal();
        }).catch((err) => {
            // there can be 3 different error codes:
            // - AUTH001: means that the given USERNAME is actually still in use
            // - AUTH002: means that the given REGISTER CODE is incorrect
            // - AUTH003: means that the given SCHEMA is incorrect
            // - AUTH004: Some OTHER error
            if (err.response.data.errCode == 'AUTH001') {
                setUsernameError(true);
            } else if (err.response.data.errCode == 'AUTH002') {
                setTokenError(true);
            } else if (err.response.data.errCode == 'AUTH003') {
                setSchemaError(true)
            } else {
                setError(true);
            }
        })
    }

    return (
        <div className='signup-final-form-container'>
            <form className="signup-form-root" onSubmit={form.handleSubmit}>
                <div className='signup-view-container'>
                    <div className='signup-member-form-root'>
                        {isMobile && <h3 className="signup-view-title">{TEXT.SIGNUP.TITLE}</h3>}
                        <div className='signup-form-sub-title'>
                            First Name
                        </div>
                        <TextField
                            name="firstname"
                            variant="outlined"
                            size="small"
                            InputProps={{
                                className: "signup-form-field-mui",
                            }}
                            color='primary'
                            value={form.values.firstname}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            error={Boolean(form.touched.firstname) && Boolean(form.errors.firstname)}
                            helperText={(form.touched.firstname && form.errors.firstname) || " "}
                            className="signup-form-field"
                        />
                        <div className='signup-form-sub-title'>
                            Last Name
                        </div>
                        <TextField
                            name="lastname"
                            variant="outlined"
                            size="small"
                            InputProps={{
                                className: "signup-form-field-mui"
                            }}
                            color='primary'
                            value={form.values.lastname}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            error={Boolean(form.touched.lastname) && Boolean(form.errors.lastname)}
                            helperText={(form.touched.lastname && form.errors.lastname) || " "}
                            className="signup-form-field"
                        />
                        <div className="signup-member-form-position-container">
                            <FormControl error className='signup-member-form-project'>
                                <div className='signup-form-sub-title'>
                                    Project
                                </div>
                                <Select
                                    name="project"
                                    size='small'
                                    value={form.values.project}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    className='signup-form-field-mui'
                                    error={Boolean(form.touched.project) && Boolean(form.errors.project)}
                                >
                                    {CONSTANTS.PROJECTS.map((item) => (
                                        <MenuItem value={item.name}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{(form.touched.project && form.errors.project) || " "}</FormHelperText>
                            </FormControl>
                            <FormControl error className='signup-member-form-project'>
                                <div className='signup-form-sub-title'>
                                    Position
                                </div>
                                <Select
                                    name="position"
                                    size='small'
                                    value={form.values.position}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    className='signup-form-field-mui'
                                    error={Boolean(form.touched.position) && Boolean(form.errors.position)}
                                >
                                    {CONSTANTS.POSITIONS.map((item) => {
                                        if (item.value != 'project-lead')
                                            return (
                                                <MenuItem value={item.value}>{item.name}</MenuItem>
                                            )
                                    })}
                                </Select>
                                <FormHelperText>{(form.touched.position && form.errors.position) || " "}</FormHelperText>
                            </FormControl>
                        </div>
                    </div>
                    <div className='signup-view-img-container'>
                        <img src={VCLIcon} alt="UBC Visual Cognition Lab Icon" className='signup-view-img' />
                        <div className='signup-view-img-title'>UBC Visual Cognition Lab</div>
                    </div>
                </div>
                <Divider className='signup-view-divider' />
                <div className='signup-auth-form-root'>
                    <div className='signup-auth-form-container'>
                        <div className='signup-auth-form-creds'>
                            <div className='signup-form-sub-title'>
                                Username
                            </div>
                            <TextField
                                name="username"
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    className: "signup-form-field-mui",
                                }}
                                value={form.values.username}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                error={Boolean(form.touched.username) && Boolean(form.errors.username)}
                                helperText={(form.touched.username && form.errors.username) || " "}
                                className="signup-form-field"
                            />
                            <div className='signup-form-sub-title'>
                                Password
                            </div>
                            <TextField
                                name="password"
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    className: "signup-form-field-mui"
                                }}
                                value={form.values.password}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                error={Boolean(form.touched.password) && Boolean(form.errors.password)}
                                helperText={(form.touched.password && form.errors.password) || " "}
                                type="password"
                                className="signup-form-field"
                            />
                        </div>
                        <div className='signup-auth-form-token'>
                            <div className='signup-form-sub-title'>
                                Sign-up Token
                            </div>
                            <TextField
                                name="token"
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    className: "signup-form-field-mui"
                                }}
                                value={form.values.token}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                error={Boolean(form.touched.token) && Boolean(form.errors.token)}
                                helperText={(form.touched.token && form.errors.token) || " "}
                                type="password"
                                className="signup-form-field"
                            />
                            <Button
                                variant="contained"
                                type="submit"
                                className='signup-form-submit-button'
                                disabled={props.disabled}
                                onClick={handleSignup}>
                                {TEXT.COMMON.SIGNUP}
                            </Button>
                            {
                                (otherError || usernameError || tokenError || schemaError) &&
                                <>
                                    <ul style={{ color: 'white', backgroundColor: 'red' }}>
                                        {usernameError && <li> Try new username</li>}
                                        {tokenError && <li> Faulty Token</li>}
                                        {schemaError && <li> One or more fields are empty</li>}
                                        {otherError && <li> CONNECTION ERROR</li>}
                                    </ul>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </form >
            <Alert variant="filled" severity="info" className='signup-token-alert'>
                NOTICE: Please ask your project lead for token.
            </Alert>
        </div >
    )

}

export default SignUpFinalForm;