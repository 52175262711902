import React, { useEffect } from "react";
import { Person } from "@components/ProjectPeople/ProjectPeople";
import { TEXT } from "@statics";
import "./ProjectV2Team.css";
import { useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from "axios";
import dotenv from "dotenv";
import { Alert, CircularProgress, Collapse } from "@mui/material";
import { Member } from "@pages/ProjectV2/types";

interface PropsOBJ {
  project: string;
}

dotenv.config();
const baseURL = process.env.REACT_APP_API_URL;
const ORDER_OF_POSITIONS = ['principal investigator', 'lab secondary', 'project lead', 'coding team lead', 'lab manager', 'assistant lab manager', 'social coordinator', 'workshop coordinator', 'project manager', 'lead developer', 'project secondary', 'research assistant (co-lead)', 'research assistant', 'co-pilot', 'volunteer', '402 student', '...'];


const ProjectTeamV2: React.FC<PropsOBJ> = (props) => {

  const { project } = props;

  const [openAlumniCollapse, setOpenAlumniCollapse] = useState<boolean>(false);

  // list of all non-alumni team members
  const [teamMembers, setTeamMembers] = useState<Array<Member>>([]);
  // list of all alumni team members
  const [alumni, setAlumni] = useState<Array<Member>>([]);

  const [callComplete, setCallComplete] = useState<boolean>(false);
  const [resSuccess, setSuccess] = useState<boolean>(false);

  const getMembers = async () => {
    await axios
      .get(`${baseURL}/api/members/${project}`)
      .then((response) => {
        setTeamMembers(filterByPosition(response.data.data, false));
        setAlumni(filterByPosition(response.data.data, true));
        setSuccess(true);
      })
      .catch((err) => {
        // do nothing with the error
      });
    setCallComplete(true);
  };

  useEffect(() => {
    getMembers();
  }, [])

  function filterByPosition(members: Member[], isAlumni: boolean) {

    // Convert all positions to lower case for case-insensitive comparison
    const lowerCasePositions = ORDER_OF_POSITIONS.map(position => position.toLowerCase());

    // First, filter and map members based on validity of their position and if their alumni status matches isAlumni
    const filteredAndMappedMembers = members.filter((item) => {
      return (item.isAlumni == isAlumni);
    });

    // Then, sort the results based on the order of positions
    const orderedMembers = filteredAndMappedMembers.sort((a, b) => {
      const posA = lowerCasePositions.indexOf(a.positions[0].position.toLowerCase());
      const posB = lowerCasePositions.indexOf(b.positions[0].position.toLowerCase());
      return posA - posB;
    });

    return orderedMembers;
  }

  return (
    <div className="project-subcontent-container">
      <div className="project-team">
        <div className="team-header">
          <p>Team Members</p>
        </div>
        <hr />
      </div>
      <div className='project-team-main-container'>
        {callComplete ?
          (resSuccess ? (
            <>
              <div>
                {teamMembers.length > 0 ? (
                  teamMembers.map((member, index) => {
                    // Find the matching position object based on currentProject
                    const matchingPosition = member.positions.find(
                      (position) => position.project === project
                    );

                    // Use the matching position object to display the position name, 
                    // otherwise fallback to the first position
                    const displayedPosition = matchingPosition?.position || member.positions[0].position;

                    return (
                      <Person
                        key={index}
                        name={member.name.firstname + " " + member.name.lastname}
                        involvement={displayedPosition.toString()}
                        description={member.blurb ? member.blurb : ''}
                        isAlumni={member.isAlumni}
                      />
                    )
                  })
                ) : (
                  <Alert severity="info" className="project-team-prompt-string">
                    {TEXT.PROJECT_TEAM_PAGE.EMPTY_MEMBER_LIST}
                  </Alert>
                )
                }
              </div>
              <div style={{marginBottom:'2rem', paddingBottom:'1rem'}}>
                <div className="team-header">
                  <p>Alumni</p>
                </div>
                <hr />
                <Collapse in={openAlumniCollapse}>
                  {alumni.length > 0 ? (
                    alumni.map((member, index) => {
                      // Find the matching position object based on currentProject
                      const matchingPosition = member.positions.find(
                        (position) => position.project === project
                      );

                      // Use the matching position object to display the position name, 
                      // otherwise fallback to the first position
                      const displayedPosition = matchingPosition?.position || member.positions[0].position;

                      return (<Person
                        key={index}
                        name={member.name.firstname + " " + member.name.lastname}
                        involvement={displayedPosition.toString()}
                        description={member.blurb ? member.blurb : ''}
                        isAlumni={member.isAlumni}
                      />)
                    })
                  ) : (
                    <Alert severity="info" className="project-team-prompt-string">
                      {TEXT.PROJECT_TEAM_PAGE.EMPTY_ALUMNI_LIST}
                    </Alert>
                  )
                  }
                </Collapse>
                <div className="project-alumni-collapse-button" onClick={() => setOpenAlumniCollapse(!openAlumniCollapse)}>
                  {openAlumniCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </div>
              </div>
            </>
          ) : (
            <Alert severity="error" className="project-team-prompt-string">
              {TEXT.PROJECT_TEAM_PAGE.RESPONSE_ERROR}
            </Alert>
          )
          ) : (
            <CircularProgress></CircularProgress>
          )
        }
      </div>
    </div>
  );
};

export default ProjectTeamV2;
