import { TimelineInfo } from '@pages/Timeline/types';

import styles from './HyperlinkInput.module.css';
import { useEffect, useState } from "react";

interface PropsOBJ {
  setTimeline:(input:TimelineInfo) => void
  timeline: TimelineInfo;
}

const HyperlinkInput = ({timeline, setTimeline}:PropsOBJ) => {
  var tags = timeline.hyperlinks

  const addTag = () => {
    let tag = (document.getElementById("hyperlinkInput") as HTMLInputElement).value;
    if (tag =="")
      return
    tags = [...tags, tag];
    (document.getElementById("hyperlinkInput") as HTMLInputElement).value = "";
    setTimeline({...timeline, hyperlinks:tags})
  }

  const removeTag = (index: number) => {
    tags = tags.filter((el, i) => i !== index);
    setTimeline({...timeline, hyperlinks:tags})
  }

  return (
    <form className={styles.inputForm} onSubmit={e => { e.preventDefault(); }}>
      <label>Hyperlink(s)</label><br></br>
      <input id="hyperlinkInput" placeholder='e.g. https://www.google.com/'></input>
      <button type="button" onClick={addTag}>Add</button>
      <div className={styles.tagBox}>
      { tags.map((tag, index) => (
          <div className={styles.tagItem}>
              <span className={styles.text}>{tag}</span>
              <span className={styles.close} onClick={() => removeTag(index)}>&times;</span>
          </div>
        ))}
      </div>
    </form>
  )
}

export default HyperlinkInput