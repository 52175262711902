import React, { useState } from 'react';
import { selectIsLoggingIn } from '@redux/slices/AuthRedux';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import Modal, { ModalInjectedProps } from '@components/generics/Modal';
import { CONSTANTS, TEXT } from '@statics';
import { useHandleLogin } from '@services/authService';
import VCLIcon from "@statics/images/vcl-logo-2023.png";
import CloseIcon from '@mui/icons-material/Close';
import './SignUpView.css';
import { Alert, Divider, IconButton } from '@mui/material';
import SignUpFinalForm from './SignUpFinalForm/SignUpForm';
import { appActions } from '@redux/slices/AppRedux';

type SignUpViewProps = Partial<ModalInjectedProps>;

const SignUpView: React.FC<SignUpViewProps> = (props) => {
  const { handleClose } = props;
  const isLoggingIn = useAppSelector(selectIsLoggingIn);

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 500);

  /* Modify the code below after the backend implementation is done to create a User
   *
   **/

  const { handleLogin, error, success } = useHandleLogin();

  const handleUserLogin = (user: string, pwd: string) => handleLogin(user, pwd);

  const dispatch = useAppDispatch();

  // Implememt this function when Sign Up Modal is done
  const handleOpenLoginModal = () => {
    dispatch(
      appActions.openModal({
        key: CONSTANTS.MODALS.LOGIN,
      })
    )

    handleClose && handleClose();
  };


  React.useEffect(() => {
    if (success) {
      handleClose && handleClose();
    }
  }, [handleClose, success]);

  // This is there just to check if the user resizes the screen width on a device
  // it will mount the changes if the screen width changes
  React.useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 500);
    };

    window.addEventListener("resize", handleResize);

    return () => {
        window.removeEventListener("resize", handleResize);
    };
}, []);

  return (
    <>
    <div className="signup-view-root">
      <div className='signup-view-close-button-container'>
      {!isMobile && <h3 className="signup-view-title">{TEXT.SIGNUP.TITLE}</h3>}
        <IconButton onClick={handleClose} disabled={isLoggingIn} aria-label="close">
          <CloseIcon fontSize='large'/>
        </IconButton>
      </div>
      {/* Modify the handleLogin to handleSignup when backend implementation is done */}
      <SignUpFinalForm disabled={isLoggingIn} handleOpenLoginModal={handleOpenLoginModal}></SignUpFinalForm >
    </div>
    <div className='signup-view-new-account'>
    {TEXT.SIGNUP.LOGIN_ACCOUNT}<span className="signup-view-signup-link" onClick={handleOpenLoginModal}>{TEXT.SIGNUP.LOGIN_ACCOUNT_LINK}</span>
    </div>
    </>
  );
};

export const SignUpModal = () => {
  return (
    <Modal name="signup" modalKey={CONSTANTS.MODALS.SIGNUP}>
      <SignUpView />
    </Modal>
  );
};

export default SignUpView;