
import { useState, useEffect } from "react";
import "./People.css";
import axios from "axios";
import dotenv from "dotenv";
import { MdAccountCircle } from "react-icons/md";
import Alert from "@mui/material/Alert";
import TEXT from "@statics/text";
import { CircularProgress, Collapse } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PageNav from "@components/PageNav/PageNav";
import { Member } from "@pages/ProjectV2/types";



dotenv.config();
const baseURL = process.env.REACT_APP_API_URL;
const IS_WIP = process.env.REACT_APP_WIP === "development";

const ORDER_OF_POSITIONS = ['principal investigator', 'lab secondary', 'project lead', 'coding team lead', 'lab manager', 'assistant lab manager', 'social coordinator', 'workshop coordinator', 'project manager', 'lead developer', 'project secondary', 'research assistant (co-lead)', 'research assistant', 'co-pilot', 'volunteer', '402 student', '...'];


const People = () => {
  const dummyList: Array<string> = [
    "Management",
    "Coding Team",
    "Correlation",
    "Ideo",
    "Image Transitions",
    "NCIS",
    "NOVA",
    "SHIVA",
  ];

  // the page will be defaulted to bein on the first grid item
  const [currentProject, setCurrProject] = useState<string>(dummyList[0]);

  const [currentList, setList] = useState<Array<Member>>([]);

  const [resSuccess, setSuccess] = useState<boolean>(false);

  const [callComplete, setCallComplete] = useState<boolean>(true);


  // This is for styles, will highlight the first select nav-item for the user
  //  Also, makes an api call to get all the lab's members 
  useEffect(() => {
    getMembers();
    document
      .getElementById(dummyList[0].toLowerCase())!
      .classList.add("selected-item");
  }, []);

  const getMembers = async () => {
    await axios
      .get(`${baseURL}/api/members`)
      .then((response) => {
        setList(response.data.data);
        setSuccess(true);
      })
      .catch((err) => {
        // do nothing with the error
        // console.log(err);
      });
    setCallComplete(false);
    // Height of content container is set to a preset value to ensure footer isn't right up against the
    //  people page nav. This call ensures the height matches the correct height of the displayed data
    var element = document.getElementById('content-display-transition');
    if (element) {
      element.style.height = 'fit-content';
    }

    // Allows for the backend data to be displayed via a transition
    element = document.getElementById('member-list-transition');
    if (element) {
      element.style.opacity = '1';
    }
  };

  // filters members to ensure they match the current viewed project and their alumni status
  const filterMembers = (isAlumni: boolean) => {
    return (
      currentList
        .filter((item) => {
          if (currentProject == "Management") {
            return item.positions.some(
              (position) =>
                position.position === 'Lab Manager' ||
                position.position === 'Principal Investigator' ||
                position.position === 'Lab Secondary' ||
                position.position === 'Assistant Lab Manager' ||
                position.position === 'Social Coordinator' ||
                position.position === 'Workshop Coordinator'
            ) && item.isAlumni === isAlumni;
          }
          else {
            return item.positions.some(
              (position) => position.project === currentProject
            ) && item.isAlumni === isAlumni;
          }
        })
    )
  }

  function filterAndCreatePersonsByPosition(positions: string[], isAlumni: boolean) {

    // Convert all positions to lower case for case-insensitive comparison
    const lowerCasePositions = positions.map(position => position.toLowerCase());

    // First, filter members
    const filteredAndMappedMembers = filterMembers(isAlumni).filter((item) => {
      return item.positions.some(
        (position) => lowerCasePositions.includes(position.position.toLowerCase())
      );
    });

    // Then, sort the results based on the order of positions
    const orderedMembers = filteredAndMappedMembers.sort((a, b) => {
      const posA = lowerCasePositions.indexOf(a.positions[0].position.toLowerCase());
      const posB = lowerCasePositions.indexOf(b.positions[0].position.toLowerCase());
      return posA - posB;
    });

    return orderedMembers.map((item, index) => createSinglePerson(item, index));
  }


  const createSinglePerson = (item: Member, index: number) => {

    // Find the matching position object based on currentProject
    const matchingPosition = item.positions.find(
      (position) => position.project === currentProject
    );

    // Use the matching position object to display the position name, 
    // otherwise fallback to the first position
    const displayedPosition = matchingPosition?.position || item.positions[0].position;

    return (
      <div key={index} className="people-lab-member">
        <div className="icon-container">
          <MdAccountCircle
            // className='icon'
            size={125}
          />
        </div>
        <div className="info-container">
          <div className="name">
            <h2>
              {item.name.firstname + " " + item.name.lastname}
            </h2>
          </div>
          <div className="position">
            <h3>{displayedPosition}</h3>
          </div>
          <div className="message">
            {item.blurb != '' && item.blurb}
          </div>
        </div>
      </div>
    )
  }

  const [openAlumniCollapse, setOpenAlumniCollapse] = useState<boolean>(false);

  return (
    <>
      <div className="main-content">
        <div className="project-header">
          <h1 className="people-page-title">OUR TEAM</h1>
          <h4 className="people-page-description">
            Meet the members of our lab and their respective teams
          </h4>
        </div>
        {/*
                    The items within the grid should be done dynamically, it should have inline styling for the number of items, instead of it being inside the css
                     - An issue that comes with this is the that the nav bar width will become too wide and some titles will not fit within each grid component
                */}
        <div>
          <PageNav items={dummyList}
            currentSelection={currentProject}
            onItemSelected={(item) => {
              setCurrProject(item)
            }}
            width={"70%"}
            defaultColumns={8}
          />
        </div>
        <div className="content-display" id='content-display-transition'>
          <div className="member-list" id='member-list-transition'>
            {callComplete ? (
              <CircularProgress></CircularProgress>
            ) : (resSuccess ? (
              <>
                {filterMembers(false).length > 0 ?
                  (
                    <div>
                      {filterAndCreatePersonsByPosition(ORDER_OF_POSITIONS, false)}
                    </div>
                  ) : (
                    <Alert severity="info" className="people-page-prompt-string">
                      {TEXT.PEOPLE_PAGE.EMPTY_MEMBER_LIST}
                    </Alert>
                  )
                }
                <div className='people-member-alumni-divider' />
                <Collapse in={openAlumniCollapse}>
                  {filterMembers(true).length > 0 ?
                    (
                      <div>
                        {filterAndCreatePersonsByPosition(ORDER_OF_POSITIONS, true)}
                      </div>
                    ) : (
                      <Alert severity="info" className="people-page-prompt-string">
                        {TEXT.PEOPLE_PAGE.EMPTY_ALUMNI_LIST}
                      </Alert>
                    )
                  }
                </Collapse>
                <div className="people-alumni-collapse-button" onClick={() => setOpenAlumniCollapse(!openAlumniCollapse)}>
                  {openAlumniCollapse ? 'Hide Alumni' : 'Show Alumni'}
                  {openAlumniCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </div>
              </>
            ) : (
              <Alert severity="error" className="people-page-prompt-string">
                {TEXT.PEOPLE_PAGE.RESPONSE_ERROR}
              </Alert>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default People;
