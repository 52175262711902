import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './AddTimelineEntry.module.css';
import { useSelector } from 'react-redux';
import TitleInput from '@components/EditTimeline/TitleInput/TitleInput';
import DateInput from '@components/EditTimeline/DateInput/DateInput';
import ProjectInput from '@components/EditTimeline/ProjectInput/ProjectInput';
import CategoryInput from '@components/EditTimeline/CategoryInput/CategoryInput';
import ContributorInput from '@components/EditTimeline/ContributorInput/ContributorInput';
import HyperlinkInput from '@components/EditTimeline/HyperlinkInput/HyperlinkInput';
import DescriptionInput from '@components/EditTimeline/DescriptionInput/DescriptionInput';
import Controls from '@components/EditTimeline/Controls/Controls';
import { MdErrorOutline } from "react-icons/md";
import { TimelineInfo } from '../types';

interface TimelineProps { }


const baseURL = process.env.REACT_APP_API_URL;
const AddTimelineEntry: React.FC<TimelineProps> = (props) => {


  const [timeline, setTimeline] = useState<TimelineInfo>({ title: "", descriptions: "", date: new Date(), project: "", author: "", categories: [], contributors: [], hyperlinks: [] });
  const history = useHistory();
  const [showError, setError] = useState<boolean>(false);
  const [currentCategories, setCategories] = useState<string[]>([])
  const [finished, setFinished] = useState<boolean>(false);

  // gets the currently logged in User's authentication details
  const currUser = useSelector((state: any) => state.auth);

  useEffect(() => {
    try {
      if (!currUser.isLoggedIn)
        throw Error("Inable to access this page because you do not have proper credentials")

    } catch (err: unknown) {
      history.push("/")
    }
  }, [])

  const toggleCategory = (category: string): void => {
    if (currentCategories.includes(category)) {
      setCategories(currentCategories.filter((c) => c != category))
      document.getElementById(category)!.style.backgroundColor = '#DEE3EA';
      document.getElementById(category)!.style.color = '#1C426D';
    } else {
      setCategories([...currentCategories, category])
      document.getElementById(category)!.style.backgroundColor = '#1C426D';
      document.getElementById(category)!.style.color = '#DEE3EA';
    }
  };

  function isEmpty(value:string) {
    return (value == null || (typeof value === "string" && value.trim().length === 0));
  }
  
  function replaceSlashes(inputString:string) {
    return inputString.replace(/\//g, '-'); 
  }

  function incrementDateByOneDay(date:string) {
    // Create a new Date object to avoid mutating the original date
    let newDate = new Date(date);
    // Increment the day by 1
    newDate.setDate(newDate.getDate() + 1);
    return newDate.toISOString();
}


  const add = async () => {
    
    setError(false);

    if (isEmpty(timeline.title) || isEmpty(timeline.project) || (currentCategories.length <= 0) || isEmpty(timeline.descriptions)) {
      setError(true)
      return
    }

    await axios({
      method: "post",
      url: `/api/snapshots`,
      data: {
        author: currUser.username,
        title: timeline.title,
        project: timeline.project,
        categories: currentCategories,
        descriptions: timeline.descriptions,
        hyperlinks: timeline.hyperlinks,
        contributors: timeline.contributors,
        date:(timeline.date).toISOString()
      },
    }).then((res) => {
      setError(false);
      setFinished(true);
    }).catch(err => {
      console.log(err)
      setError(true)
    })

  }

  return (
    <div className={styles.container}>
       <div className={styles.info}>
        <div className={styles.headers}>
          <h1 className={styles.header}>Add New Timeline Entry</h1>
          <hr></hr>
          {!finished && <h2 className={styles.subHeader} style={{ color: 'red', fontSize: '15px' }}>NOTE: * = mandatory field</h2> }
        </div>
        {!finished ? <main>
          <div className={styles.gridContainer}>
            <div className={styles.basicInfo}>
              <div><TitleInput timeline={timeline} setTimeline={setTimeline} /></div>
              <div><DateInput timeline={timeline} setTimeline={setTimeline}/></div>
            </div>

            <div className={styles.projectInfo}>
              <ProjectInput timeline={timeline} setTimeline={setTimeline} />
            </div>

            <div className={styles.categoryInfo}>
              <CategoryInput timeline={timeline} setTimeline={setTimeline} selectedCategories={currentCategories} toggleCategory={toggleCategory} />
            </div>

            <div className={styles.contributorInfo}>
              <div><ContributorInput timeline={timeline} setTimeline={setTimeline} /></div>
              <div><HyperlinkInput timeline={timeline} setTimeline={setTimeline} /></div>
            </div>

            <div className={styles.descriptionInfo}>
              <DescriptionInput timeline={timeline} setTimeline={setTimeline} />
            </div>

            <div className={styles.controls}>
              <Controls save={add} />
            </div>
            
          </div>
          
        </main> : 
        <div>
          <h1 className={styles.entryCompleteTitle}>Entry Complete!</h1>
          <div className={styles.entryCompleteButton} style={{backgroundColor:'#1C426D', color:'white', cursor:'pointer'}} onClick={() => window.location.href ='/timeline'}>
            Make another entry
          </div>
          <div className={styles.entryCompleteButton} style={{backgroundColor:'#EAE9E9', color:'black', cursor:'pointer'}} onClick={() => window.location.href ='/'}>
            Return to home
          </div>
        </div>
        }
        <div className={styles.errorMessageContainer} style={{visibility:`${showError ? 'visible' : 'hidden'}`, cursor:'pointer'}}>
              <MdErrorOutline className={styles.errorMessageIcon}/>
              <div>
                <b>WARNING: An error occured. Please try again.</b>
              </div>
        </div>
      </div> 
    </div>
    //</div>)
  )
}

export default AddTimelineEntry