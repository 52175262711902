import { LoginModal } from '@components/LoginView';
import { SignUpModal } from '@components/SignUpView/SignUpView';

const Modals = () => {
  return (
    <>
      <LoginModal />
      <SignUpModal />
    </>
  );
};

export default Modals;