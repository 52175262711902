import { TimelineInfo } from '@pages/Timeline/types';

import styles from './TitleInput.module.css';

interface PropsOBJ {
  setTimeline:(input:TimelineInfo) => void
  timeline: TimelineInfo;
}

const TitleInput = ({timeline, setTimeline}:PropsOBJ) => {


  return (
    <form className={styles.inputForm}>
      <label>Title</label><label style={{color: "red"}}>*</label><br></br>
      <input placeholder='e.g. Updated documentation website' value={timeline.title} onChange={(res) => {setTimeline({...timeline, title:String(res.target.value)})}}></input>
    </form>
  )
}

export default TitleInput