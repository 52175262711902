import { RouteComponentProps } from "react-router-dom";
import './ProjectV2Wrapper.css';
import ProjectRouter from '../ProjectRouter/ProjectRouter';
import { useState, useEffect } from 'react';
import { useAppDispatch } from "@redux/hooks";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Collapse } from "@mui/material";
import { appActions } from "@redux/slices/AppRedux";
import PROJECT_TEXT from "@statics/projectsV2";
import { useLocation } from 'react-router-dom';
import { PROJECT } from "@statics/projectsV2";
import { set, sub } from "date-fns";
import ROUTES from "@statics/routes";

interface MatchParams {
    project_id: string;
}
interface ProjectProps extends RouteComponentProps<MatchParams> { }

interface SidebarLinkOBJ {
    pseudoTitle: string | null;     // property we can use to perform methods and inequalities on 
    displayedTitle: string;         // property that will be displayed to the user
    hyperlink: string;             // the link we want to redirect to
    display: boolean;               // should this item be displayed?
}

const ProjectV2Wrapper: React.FC<ProjectProps> = ({ match }) => {

    const [menuSize, setMenuSize] = useState<number>(0);
    const [openMobileDropdown, setOpenMobileDropdown] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const currentProject = PROJECT_TEXT.find((project) => project.project_title === match.params.project_id);

    if (!currentProject) {
        window.location.href = "/errorPage"
    }

    const subPages: Array<string> = [];
    const [isSubpage, setIsSubpage] = useState<boolean>(false);


    if (currentProject && currentProject.subpages) {
        currentProject.subpages.forEach((subpage) => {
            subPages.push(subpage.project_title);
        });
    }

    function handleSubpage() {
        if (subPages) {
            setIsSubpage(true);
        }
    }

    function handleResize() {
        if (window.innerWidth <= 700) {
            // mobile navbar
            setMenuSize(
                132
            )
        } else {
            // global-nav-bar
            setMenuSize(
                83.5
            )
        }

    }
    useEffect(() => {
        dispatch(appActions.setInProjectsPage(true));
        setMenuSize(document.getElementById("global-nav-bar")!.offsetHeight)

        // handle if there are subpages
        handleSubpage();
        // handle initial resize on window depending on screen size
        handleResize();
        // Set up the event listener
        window.addEventListener('resize', handleResize);
        console.log(menuSize)

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
            dispatch(appActions.setInProjectsPage(false));
        };
    }, []); // Empty dependency array ensures the effect runs only on mount and unmount




    const listOfLinks: SidebarLinkOBJ[] = [
        {
            pseudoTitle: null,
            displayedTitle: 'Home',
            hyperlink: `/projects/${match.params.project_id}/`,
            // subpages will be handled unqiuely at the render of these links
            display: true,
        },
        {
            pseudoTitle: 'join',
            displayedTitle: 'Join',
            hyperlink: `/projects/${match.params.project_id}/join`,
            display: currentProject!.joinTeam!.whatWeDo.length > 0
        },
        {
            pseudoTitle: 'team',
            displayedTitle: 'Team',
            hyperlink: `/projects/${match.params.project_id}/team`,
            display: true,
        },
        {
            pseudoTitle: 'publications',
            displayedTitle: 'Publications',
            hyperlink: `/projects/${match.params.project_id}/publications`,
            display: currentProject!.publications != undefined
        },
    ]

    function getLastPart(url: string): string | null {
        // Regex to match the last part of the URL
        const regex = /\/projects\/[^\/]+\/([^\/]+)/;
        const match = url.match(regex);
        if (match) {
            return match[1];
        } else {
            return null;
        }
    }

    const location = useLocation();
    var lastPartURL = getLastPart(location.pathname)

    // Construct the full URL
    const fullUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

    if (!(lastPartURL === "join" || lastPartURL === "team" || lastPartURL === "publications" || lastPartURL === null || location.pathname === "/projects/Correlation/Mainstream Correlation" || location.pathname === "/projects/Correlation/Sequence Correlation" || location.pathname === "/projects/Coding Team/Website Development" || location.pathname === "/projects/Coding Team/Experiment Framework Maintenance")) {
        window.location.href = "/errorPage";
    }

    const renderedLinks: any = listOfLinks.map((item: SidebarLinkOBJ, index: number) => {

        if (!item.display)
            return
        if (item.displayedTitle != "Home") {
            return (
                <div className="single-table-content" key={index}>
                    <a className="project-content-container-href"
                        // href={item.hyperlink}
                        onClick={() => {
                            window.location.href = item.hyperlink
                        }}
                        style={{ color: `${item.pseudoTitle === lastPartURL ? "#4C6199" : "black"}` }}>{item.displayedTitle}
                    </a>
                </div>
            )
        } else {

            if (subPages) {
                return (
                    <div>
                        <div className="single-table-content" key={index}>
                            <a className="project-content-container-href" href={item.hyperlink} style={{ color: `${item.pseudoTitle === lastPartURL ? "#4C6199" : "black"}` }}>{item.displayedTitle}</a>
                        </div>
                        {
                            subPages.map((subpageTitle: string, index1: number) => {
                                return (
                                    <div className="single-table-content project-subpage" key={index1}>
                                        <a className="project-content-container-href" href={`/projects/${match.params.project_id}/${subpageTitle}`} style={{ color: `${subpageTitle === lastPartURL ? "#4C6199" : "black"}` }}>{subpageTitle}</a>
                                    </div>
                                );
                            })
                        }
                    </div>
                )
            } else {
                return (
                    <div>
                        <div className="single-table-content" key={index}>
                            <a className="project-content-container-href" href={item.hyperlink} style={{ color: `${item.pseudoTitle === lastPartURL ? "#4C6199" : "black"}` }}>{item.displayedTitle}</a>
                        </div>
                    </div>
                )

            }
        }
    })

    return (
        <div className="project-content-background">
            <div className="project-content-container">
                <div id="projectV2-sidebar">
                    <div>
                        <div style={{ textAlign: "center", marginTop: "1rem" }}>
                            <div className="project-sidebar-title">
                                <h2>Now Viewing</h2>
                                <h1>{match.params.project_id == 'Image Transitions' ? "I.T." : match.params.project_id}</h1>
                                <div style={{ borderBottom: '2px solid #B2C9EC' }}></div>
                            </div>
                            <div className="project-sidebar-clickable-content">
                                {
                                    renderedLinks
                                }
                            </div>

                        </div>
                    </div>
                </div>
                <div id="table-contents">
                    <div className="single-table-content" onClick={() => { setOpenMobileDropdown(!openMobileDropdown) }}>
                        <a className="project-content-container-title">{match.params.project_id}</a>
                        {openMobileDropdown ? <KeyboardArrowUpIcon sx={{ width: '27px', height: '27px' }} /> : <KeyboardArrowDownIcon sx={{ width: '27px', height: '27px' }} />}
                    </div>
                    <Collapse
                        in={openMobileDropdown}
                    >
                        {
                            renderedLinks
                        }
                    </Collapse>

                </div>
                <div className='project-router-div' style={{ paddingTop: `${menuSize}px` }}>
                    <ProjectRouter project_id={match.params.project_id} isPages={isSubpage} subpages={subPages} />
                </div>
            </div>
        </div>
    );
}

export default ProjectV2Wrapper;