import GenericLink from "@components/generics/Link";
import { Menu, MenuItem } from "@mui/material";
import { TEXT, CONSTANTS } from "@statics";
import RESOURCES from "@statics/resources";
import ROUTES from "@statics/routes";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { setMenuSizeAfterTransition, setNoHover, stopTransitionEventPropagation } from "../utils/utils";
import { useLocation } from "react-router-dom";

// INTERFACES AND TYPES 
interface props {
    active: string,
    refs: string,
}

// type definition for close handlers
type OnCloseHandler = () => void;


export default function LinkWithMenu({ active, refs }: props) {
        
    // hook from react-router-dom to track active page
    const location = useLocation();

    // STATES
    const [projectAnchorEl, setProjectAnchorEl] = React.useState<null | HTMLElement>(null);
    const projectOpen = Boolean(projectAnchorEl);

    const [resourceAnchorEl, setResourceAnchorEl] = React.useState<null | HTMLElement>(null);
    const resourceOpen = Boolean(resourceAnchorEl);

    // HANDLERS
    const handleProjectMenuOpen = ( event: React.MouseEvent<HTMLButtonElement>) => {
        setProjectAnchorEl(event.currentTarget);
    };

    const handleResourceMenuOpen = ( event: React.MouseEvent<HTMLButtonElement>) => {
        setResourceAnchorEl(event.currentTarget);
    };

    const handleProjectMenuClose = () => { setProjectAnchorEl(null); };

    const handleResourceMenuClose = () => { setResourceAnchorEl(null); };

    let title: string;
    let baseRoute: string;
    let namesArray;
    let handleClose: OnCloseHandler;
    let handleOpen: React.MouseEventHandler<HTMLButtonElement>;
    let menuOpen: boolean;
    let anchorEl: HTMLElement | null;
    switch (refs) {
      case ROUTES.PROJECT.BASE:
        title = TEXT.PAGE_TITLES.PROJECTS;
        baseRoute = ROUTES.PROJECT.BASE;
        namesArray = CONSTANTS.PROJECTS;
        handleClose = handleProjectMenuClose;
        handleOpen = handleProjectMenuOpen;
        menuOpen = projectOpen;
        anchorEl = projectAnchorEl;
        break;
      default:
        title = TEXT.PAGE_TITLES.RESOURCES;
        baseRoute = ROUTES.RESOURCES!.BASE;
        namesArray = RESOURCES;
        handleClose = handleResourceMenuClose;
        handleOpen = handleResourceMenuOpen;
        menuOpen = resourceOpen;
        anchorEl = resourceAnchorEl;
    }

    return (
      <React.Fragment key={refs}>
        {/* "button" part of dropdown */}
        <div className="nav-link-container">
          <button
            className={`nav-link link-style ${active}`}
            id="basic-button"
            onClick={handleOpen}
          >
            {title}
          </button>
          <KeyboardArrowDownIcon className={`nav-link ${active}`} />
        </div>

        {/* actual drop down menu */}
        <Menu
          className="dropdown-menu"
          id="basic-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleClose}
          onTransitionEnd={setMenuSizeAfterTransition}
          onTransitionEnter={setNoHover}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          disableScrollLock={true}
        >
          <MenuItem className="item-hoverable" onTransitionEnd={stopTransitionEventPropagation} disableRipple>
            <GenericLink
              className="all-item-text"
              name={"All " + title}
              to={`${baseRoute}`}
              onClickHandler={handleClose} 
            />
            <hr className="all-item-underline" />
          </MenuItem>
          {namesArray.map((menuItem, i) => {
            return (
              <MenuItem className="menu-item item-hoverable" key={i} onTransitionEnd={stopTransitionEventPropagation} disableRipple>
                <GenericLink
                  className={`nav-link item-name ${
                    location.pathname.split("/")[2] === menuItem.name
                      ? "active"
                      : ""
                  }`}
                  name={menuItem.name}
                  to={`${baseRoute}/${menuItem.name}`}
                  onClickHandler={handleClose} 
                />
              </MenuItem>
            );
          })}
        </Menu>
      </React.Fragment>
    );
}

