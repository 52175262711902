import { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import styles from "./EditProfile.module.css";
import Grid from "@mui/material/Grid";

const EditProfile = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const breakpoint = 700;

	useEffect(() => {
		const handleWindowResize = () => {
			setWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleWindowResize);
	}, []);

	return (
		<>
			<div id={styles.mainContent} style={{ justifyContent: "center" }}>
				<div>
					{/* prevents heading from being covered during mobile view */}
					{width < breakpoint ? (
						<>
							<br />
							<br />
							<br />
						</>
					) : (
						<></>
					)}
					<h1>Update your personal details</h1>
					<br />
					<Divider
						sx={{ borderBottomWidth: 3, marginBottom: "35px" }}
						color="#B2C9EC"
					/>
					<Grid
						container
						spacing={2}
						justifyContent="flex-start"
						alignItems="center"
					>
						<Grid item>
							<img
								id={styles.profilePicture}
								src="logo192.png"
								alt="Profile picture"
							></img>
						</Grid>
						<Grid item>
							<button className={styles.blueBtn}>Upload</button>
						</Grid>
						<Grid item>
							<button className={styles.greyBtn}>Delete</button>
						</Grid>
					</Grid>
					<br />
					<Grid container spacing={2} justifyContent="flex-start">
						<Grid item xs={6}>
							<div>
								<h4>First Name</h4>
								<input className={styles.readOnly} value=" Alice" readOnly></input>
							</div>
						</Grid>
						<Grid item xs={6}>
							<div>
								<h4>Last Name</h4>
								<input></input>
							</div>
						</Grid>
						<Grid item xs={6}>
							<div>
								<h4>Project</h4>
								<select>
									<option hidden disabled selected></option>
									<option value="Correlation">Correlation</option>
									<option value="SHIVA">SHIVA</option>
									<option value="IDEO">IDEO</option>
									<option value="Image Transitions">Image Transitions</option>
									<option value="Coding Team">Coding Team</option>
									<option value="NCIS">NCIS</option>
									<option value="NOVA">NOVA</option>
								</select>
							</div>
						</Grid>
						<Grid item xs={6}>
							<div>
								<h4>Position</h4>
								<select>
									<option hidden disabled selected></option>
									<option value="Co-pilot">Co-pilot</option>
									<option value="Tech Lead">Tech Lead</option>
									<option value="Project Manager">Project Manager</option>
									<option value="Team Lead">Team Lead</option>
								</select>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div>
								<h4>Biography</h4>
								<textarea placeholder="Add your bio here..."></textarea>
							</div>
						</Grid>
					</Grid>
					<br />
					<Grid container spacing={2} justifyContent={"flex-end"}>
						<Grid item>
							<button className={styles.greyBtn}>Cancel</button>
						</Grid>
						<Grid item>
							<button className={styles.blueBtn}>Save Changes</button>
						</Grid>
					</Grid>
				</div>
				<br />
				<br />
				<div>
					<h1>Change your password</h1>
					<br />
					<Divider
						sx={{ borderBottomWidth: 3, marginBottom: "10px" }}
						color="#B2C9EC"
					/>
					<p>
						<span>NOTE: * = mandatory field</span>
					</p>
					<br />
					<h4>
						Current password<span>*</span>
					</h4>
					<input type="password" required></input>
					<br />
					<br />
					<h4>
						New password<span>*</span>
					</h4>
					<input type="password" required></input>
					<br />
					<br />
					<h4>
						Confirm new password<span>*</span>
					</h4>
					<input type="password" required></input>
				</div>
				<br />
				<Grid container spacing={2} justifyContent={"flex-end"}>
					<Grid item>
						<button className={styles.greyBtn}>Cancel</button>
					</Grid>
					<Grid item>
						<button className="blue-btn">Save Changes</button>
					</Grid>
				</Grid>
			</div>
		</>
	);
};
export default EditProfile;
