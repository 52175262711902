import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

// Reducer imports go here
import appReducer from './slices/AppRedux';
import authReducer from './slices/AuthRedux';
import projectReducer from './slices/ProjectRedux';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  project: projectReducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
