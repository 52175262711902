import ROUTES from "./routes";
import TEXT from "./text";

const baseNav = [
	{
		TITLE: TEXT.PAGE_TITLES.HOME,
		REF: ROUTES.HOME
	},
	{
		TITLE: TEXT.PAGE_TITLES.PROJECTS,
		REF: ROUTES.PROJECT.BASE
	},
	{
		TITLE: TEXT.PAGE_TITLES.PEOPLE,
		REF: ROUTES.PEOPLE
	},
	{
		TITLE: TEXT.PAGE_TITLES.GET_INVOLVED,
		REF: ROUTES.GET_INVOLVED
	},
	{
		TITLE: TEXT.PAGE_TITLES.AFFILIATIONS,
		REF: ROUTES.AFFILIATIONS
	},
	{
		TITLE: TEXT.PAGE_TITLES.RESOURCES,
		REF: ROUTES.RESOURCES!.BASE
	},
	{
		TITLE: TEXT.PAGE_TITLES.TIMELINE,
		REF: ROUTES.TIMELINE
	},
];

const NAV = baseNav;

export default NAV;