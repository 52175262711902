
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { NAV, TEXT, CONSTANTS, ROUTES } from "@statics";
import RESOURCES from "@statics/resources";
import { useHandleLogout } from "@services/authService";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectIsLoggedIn } from "@redux/slices/AuthRedux";
import GenericLink from "@components/generics/Link";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Toolbar, } from "@mui/material";
import { selectInProjectsPage } from "@redux/slices/AppRedux";
import "./Navbar.css";
import MobileMenu from "@components/MobileNavbar";
import NavbarLinks from "./NavbarComponents/NavbarLinks";
import Logo from "./NavbarComponents/Logo";
require("dotenv").config();

// export this somewhere? sounds like a shared state
const IS_WIP = process.env.REACT_APP_WIP === "development";

const Navbar: React.FC<{}> = () => {
  const location = useLocation();
  const history = useHistory()
  const { logout } = useHandleLogout();
  const dispatch = useAppDispatch();

  const inProjectsPage = useAppSelector(selectInProjectsPage);
  return (
    <div className={inProjectsPage ? 'nav-no-shadow' : 'nav'} id="nav">
        <Toolbar className="nav-toolbar" id="global-nav-bar">
          <Logo /> 
          <NavbarLinks IS_WIP={IS_WIP} />
        </Toolbar>
      <MobileMenu />
    </div>
  );
};

export default Navbar;