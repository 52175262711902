const baseRoutes = {
  HOME: '/',
  PROJECT: {
    PATH: '/projects/:project_id/',
    BASE: '/projects',
    OVERVIEW: '/projects/overview',
  },
  PROJECT_DESCRIPTION: '/',
  JOIN_OUR_TEAM: '/join',
  TEAM: '/team',
  CONTACT: '/contact',
  ABOUT: '/about',
  GET_INVOLVED: '/get_involved',
  PROJECT_PUBLICATIONS: '/publications',
  PEOPLE: '/people',
  AFFILIATIONS: '/affiliations',
  ERROR_PAGE: '*'
} as const;

const timelineRoutes = {
  RESOURCES: {
    BASE: '/resources',
    PATH: '/resources/:resource_id'
  },
  TIMELINE: '/timeline',
  TIMELINE_EDIT: '/timeline/edit/:timeline_id',
  TIMELINE_ADD: '/timeline/add',
  PROJECT_TIMELINE: '/timeline',
  PROFILE: '/editprofile',
} as const;

const ROUTES = {
  ...baseRoutes,
  ...timelineRoutes,
} as const;



export default ROUTES;
