import './AddEditResource.css';
import { IoCloseSharp } from "react-icons/io5";
import { useMemo } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { MenuItem, TextField } from '@mui/material';
import { TEXT } from '@statics';
import { Resource, ResourceRequestBody } from '@entities/Resource';

interface EditResourcesProps {
  isVisible: boolean,
  setVisible: (bool: boolean) => void,
  subcategories: string[] | undefined,
  message: string,
  handleSubmit: (reqBody: ResourceRequestBody, id: string) => Promise<boolean>,
  resource: Resource,
}

const EditResource: React.FC<EditResourcesProps> = (props) => {

    const { isVisible, setVisible, subcategories, message, handleSubmit, resource } = props;
    
    const schema = useMemo(
        () =>
          yup.object({
            title: yup
                .string()
                .required(TEXT.RESOURCE_PAGE.TITLE_EMPTY_ERROR),
            description: yup
                .string(),
            category: yup.object().shape({
                main: yup
                    .string()
                    .required(),
                sub: yup
                    .string()
                    .required(TEXT.RESOURCE_PAGE.CATEGORY_EMPTY_ERROR),
            }).required(),
            author: yup
                .string()
                .required(TEXT.RESOURCE_PAGE.AUTHOR_EMPTY_ERROR),
            resource_link: yup
                .string()
                .matches(
                    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/,
                    TEXT.RESOURCE_PAGE.INVALID_URL_LINK_ERROR
                )
                .required(TEXT.RESOURCE_PAGE.LINK_EMPTY_ERROR),
          }),
        []
      );
    
    const form = useFormik<ResourceRequestBody>({
        initialValues: {
            title: resource.title,
            description: resource.description,
            category: {
                main: resource.category.main,
                sub: resource.category.sub,
            },
            author: resource.author,
            resource_link: resource.resource_link,
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            const submitResults = await handleSubmit(values, resource._id);
            if (submitResults) {
              setVisible(false);
            }
        },
    });    

    return (
        <div className='add-resource-content-container' style={{ display: `${isVisible ? '' : 'none'}` }}>
            <div className='add-resource-content'>
                <div className='add-resource-close-tab-container'>
                    <IoCloseSharp className='add-resource-close-tab-button' size={45}
                        onClick={() => {
                            setVisible(false);
                        }} />
                </div>
                <div className='add-resource-content-title-container'>
                    <h1>Edit Resource</h1>
                </div>
                <form className='add-resource-input-containers' onSubmit={form.handleSubmit}>
                    <div className='add-resource-input add-resource-input-containers-child add-resource-title-input-container'>
                        <h2>Title</h2>
                        <TextField
                            name="title"
                            size='small'
                            variant='outlined'
                            value={form.values.title}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            error={Boolean(form.touched.title) && Boolean(form.errors.title)}
                            helperText={form.touched.title && form.errors.title}
                            fullWidth
                        />
                    </div>
                    <div className='add-resource-input add-resource-input-containers-child' id='add-resource-description-input-container'>
                        <h2>Description</h2>
                        <TextField
                            name="description"
                            size='small'
                            variant='outlined'
                            value={form.values.description}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            error={Boolean(form.touched.description) && Boolean(form.errors.description)}
                            helperText={form.touched.description && form.errors.description}
                            fullWidth
                            multiline
                            minRows={4}
                            maxRows={4}
                        />
                    </div>
                    { subcategories && <div className='add-resource-input add-resource-input-containers-child add-resource-title-input-container'>
                        <h2>Category</h2>
                        <TextField
                            select
                            name="category.sub"
                            size='small'
                            variant='outlined'
                            defaultValue={resource.category.sub}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            error={Boolean(form.touched.category?.sub) && Boolean(form.errors.category?.sub)}
                            helperText={form.touched.category?.sub && form.errors.category?.sub}
                            fullWidth
                        >
                            {subcategories.map((cat, index) => (
                                <MenuItem key={index} value={cat}>{cat}</MenuItem>
                            ))}
                        </TextField>
                    </div>}
                    <div className='add-resource-input add-resource-input-containers-child add-resource-title-input-container'>
                        <h2>Author(s)</h2>
                        <TextField
                            name="author"
                            size='small'
                            variant='outlined'
                            value={form.values.author}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            error={Boolean(form.touched.author) && Boolean(form.errors.author)}
                            helperText={form.touched.author && form.errors.author}
                            fullWidth
                        />
                    </div>
                    <div className='add-resource-input add-resource-input-containers-child add-resource-title-input-container'>
                        <h2>Link to Resource</h2>
                        <TextField
                            name="resource_link"
                            size='small'
                            variant='outlined'
                            value={form.values.resource_link}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            error={Boolean(form.touched.resource_link) && Boolean(form.errors.resource_link)}
                            helperText={form.touched.resource_link && form.errors.resource_link}
                            fullWidth
                        />
                    </div>
                    <button className={`add-resource-submit-button add-resource-input-containers-child ${!form.isValid && 'add-resource-submit-button-disabled'}`} type='submit'>
                        Submit
                    </button>
                </form>
                {message && <p className="after-submit-message" style={{color: 'red'}}>{message}</p>}
            </div>
        </div>
    )
};

export default EditResource;